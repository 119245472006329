<!-- resources/js/MyComponent.vue -->
<template>
    <div class="bg-white block w-full flex flex-wrap h-full relative mx-auto">
        <div class="relative h-full w-full md:w-2/5" >
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative h-full w-full">
                    <div class="py-4 px-4 md:px-4 text-gray-700 gap-x-2 sm:block border-r-2 border-gray-200">
                        <div class="flex w-full mt-2 flex-wrap rounded-md shadow-sm">
                            <button @click="selectMode = 'rephrase'" :class="[selectMode == 'rephrase' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200 rounded-s-lg hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Rephrase
                            </button>
                            <button @click="selectMode = 'formal'" :class="[selectMode == 'formal' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Formal
                            </button>
                            <button @click="selectMode = 'casual'" :class="[selectMode == 'casual' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Casual
                            </button>
                            <button @click="selectMode = 'improve'" :class="[selectMode == 'improve' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Improve
                            </button>
                            <button @click="selectMode = 'simplify'" :class="[selectMode == 'simplify' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200  hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Simplify
                            </button>
                            <button @click="selectMode = 'summarize'" :class="[selectMode == 'summarize' ? 'text-[#0F2774] bg-[#77AFFB]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold bg-white border border-gray-200 rounded-e-lg hover:bg-[#77AFFB] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                Summarize
                            </button>
                        </div>
                        <textarea rows="8" v-model="rawtext" class="w-full border-gray-200 rounded-md mt-1" placeholder="What your paragraph will be about?✨" :class="{ 'border-red-500': isTextareaEmpty }" @input="isTextareaEmpty = rawtext.trim() === ''" required></textarea>
                        <div v-if="isTextareaEmpty" class="text-xs text-red-500"> {{ errorMessage }} </div>

                        <p class="text-xs text-right text-gray-700" :class="rawtext.length > 500 ? 'text-red-400':''">{{rawtext.length}}/500</p>
                        <div class="w-full py-2">
                            <div id="cf-container"></div>
                        </div>
                        <button @click="doParaphrase()" :disabled="loading" class="gap-x-2 flex text-base bg-gradient-to-b from-blue-500 via-blue-500 to-blue-300 hover:from-blue-700 hover:via-blue-700 hover:to-blue-500 text-white px-5 md:py-2 py-2 rounded-md " type="button">
                            <svg v-if="loading" class="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28 29" fill="none" class="my-auto">
                                <path d="M24.8975 8.66676L22.6993 10.865L18.2734 6.43906L20.4716 4.24086C21.0913 3.62123 21.8879 3.32617 22.6846 3.32617C23.4812 3.32617 24.2779 3.62123 24.8975 4.24086C26.122 5.46536 26.122 7.44226 24.8975 8.66676Z" fill="white"/>
                                <path opacity="0.4" d="M22.696 10.8644L5.71537 27.8451C4.49087 29.0696 2.51397 29.0696 1.28947 27.8451C0.064969 26.6206 0.064969 24.6437 1.28947 23.4192L18.2701 6.43848L22.696 10.8644Z" fill="#ECF8F9"/>
                                <path d="M10.8073 2.76693L11.4122 0.716266C11.4712 0.524477 11.4122 0.317935 11.2794 0.170405C11.1466 0.0228748 10.9106 -0.0361372 10.7188 0.0228748L8.66814 0.627748L6.61747 0.0228748C6.42568 -0.0361372 6.21914 0.0228748 6.07161 0.155652C5.92408 0.303182 5.87982 0.509724 5.93883 0.701513L6.52895 2.76693L5.92408 4.8176C5.86506 5.00939 5.92408 5.21593 6.05685 5.36346C6.20438 5.51099 6.41093 5.55525 6.60271 5.49624L8.66814 4.90612L10.7188 5.51099C10.7778 5.52575 10.8221 5.5405 10.8811 5.5405C11.0286 5.5405 11.1614 5.48149 11.2794 5.37822C11.4269 5.23069 11.4712 5.02414 11.4122 4.83235L10.8073 2.76693ZM4.90612 11.6187L5.51099 9.56807C5.57 9.37628 5.51099 9.16974 5.37822 9.02221C5.23069 8.87468 5.02414 8.83042 4.83235 8.88943L2.76693 9.47955L0.716266 8.87468C0.524477 8.81566 0.317935 8.87468 0.170405 9.00745C0.022875 9.15498 -0.0213843 9.36153 0.0376278 9.55332L0.627748 11.6187L0.0228748 13.6694C-0.0361373 13.8612 0.022875 14.0677 0.155652 14.2152C0.303182 14.3628 0.509724 14.407 0.701513 14.348L2.75218 13.7431L4.80285 14.348C4.84711 14.3628 4.90612 14.3628 4.96513 14.3628C5.11266 14.3628 5.24544 14.3038 5.36346 14.2005C5.51099 14.053 5.55525 13.8464 5.49624 13.6546L4.90612 11.6187ZM27.0356 18.9952L27.6405 16.9445C27.6995 16.7528 27.6405 16.5462 27.5077 16.3987C27.3602 16.2512 27.1536 16.2069 26.9618 16.2659L24.9112 16.8708L22.8605 16.2659C22.6687 16.2069 22.4622 16.2659 22.3146 16.3987C22.1671 16.5462 22.1228 16.7528 22.1819 16.9445L22.7867 18.9952L22.1819 21.0459C22.1228 21.2377 22.1819 21.4442 22.3146 21.5917C22.4622 21.7393 22.6687 21.7835 22.8605 21.7245L24.9112 21.1196L26.9618 21.7245C27.0061 21.7393 27.0651 21.7393 27.1241 21.7393C27.2716 21.7393 27.4044 21.6803 27.5224 21.577C27.67 21.4295 27.7142 21.2229 27.6552 21.0311L27.0356 18.9952Z" fill="white"/>
                            </svg>
                            Rewrite My Paragraph
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1 h-auto p-1 bg-white border-l w-2/5">
            <QuillEditor theme="snow" v-model:content="editortext" contentType="text" toolbar="minimal" class="h-auto" />
            <ActionButtonsComponent :text-to-copy="editortext" :editortext="editortext"/>
            <!--            <div v-for="(paragraph, index) in editortext" :key="index" class="p-3">
                <p
                    contenteditable="true"
                    @input="updateText($event, index)"
                    class="w-full rounded-md"
                    :style="{ minHeight: '50px', border: '1px solid #ccc', padding: '15px', position: 'relative' }">
                    {{ paragraph }}
                    <button class="flex absolute right-1 bottom-1">
                         <span class="border border-[#f3ac4dc9] rounded-l-lg px-1 py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" title="download" class="hover:text-[#F6BE73]">
                                <path d="M14.7928932,11.5 L11.6464466,8.35355339 C11.4511845,8.15829124 11.4511845,7.84170876 11.6464466,7.64644661 C11.8417088,7.45118446 12.1582912,7.45118446 12.3535534,7.64644661 L16.3535534,11.6464466 C16.5488155,11.8417088 16.5488155,12.1582912 16.3535534,12.3535534 L12.3535534,16.3535534 C12.1582912,16.5488155 11.8417088,16.5488155 11.6464466,16.3535534 C11.4511845,16.1582912 11.4511845,15.8417088 11.6464466,15.6464466 L14.7928932,12.5 L4,12.5 C3.72385763,12.5 3.5,12.2761424 3.5,12 C3.5,11.7238576 3.72385763,11.5 4,11.5 L14.7928932,11.5 Z M16,4.5 C15.7238576,4.5 15.5,4.27614237 15.5,4 C15.5,3.72385763 15.7238576,3.5 16,3.5 L19,3.5 C20.3807119,3.5 21.5,4.61928813 21.5,6 L21.5,18 C21.5,19.3807119 20.3807119,20.5 19,20.5 L16,20.5 C15.7238576,20.5 15.5,20.2761424 15.5,20 C15.5,19.7238576 15.7238576,19.5 16,19.5 L19,19.5 C19.8284271,19.5 20.5,18.8284271 20.5,18 L20.5,6 C20.5,5.17157288 19.8284271,4.5 19,4.5 L16,4.5 Z" transform="rotate(90 12.5 12)"/>
                            </svg>
                         </span>
                        <span class="border border-[#f3ac4dc9] rounded-r-lg px-1 py-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="hover:text-[#F6BE73]">
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                        </svg>
                      </span>
                    </button>
                </p>
            </div>-->

            <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';

export default {
    components: {
        QuillEditor,
        ActionButtonsComponent,
        ErrorPopupComponent
    },
    props: {
        translations: Object,
    },
    data() {
        return {
            sitekey: '0x4AAAAAAAfG4Tr6iUYG81ci',
            token: null,
            rawtext: '',
            editortext: '',
            loading: false,
            isTextareaEmpty: false,
            errorMessage: "This field is required.",
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",
            isDropdownVisible: false,
            isDropdownVisibleCount: false,
            selectedOption: '1 Paragraph',
            selectMode:'rephrase',
            options: [
                '1 Paragraph',
                '2 Paragraph',
                '3 Paragraph',
            ],
            label: 'Paragraph',
        };
    },
    mounted() {
        this.renderCf();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeMount() {
        this.cfInit();
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        cfInit() {
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        updateText(event, index) {
            // Update the text in the data model
            this.$set(this.editortext, index, event.target.innerText);
        },
        renderCf() {
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function (token) {
                        _this.verify(token);
                    },
                });
            };
        },
        adjustHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
        },
        verify(token) {
            this.token = token;
        },
        toggleDropdown() {
            this.isDropdownVisible = !this.isDropdownVisible;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.toggleDropdown();
        },
        handleClickOutside(event) {
            const dropdown = this.$refs.dropdown;
            const dropdownCount = this.$refs.dropdownCount;
            if (dropdown && !dropdown.contains(event.target)) {
                this.isDropdownVisible = false;
            }
            if (dropdownCount && !dropdownCount.contains(event.target)) {
                this.isDropdownVisibleCount = false;
            }
        },
        doParaphrase() {
            this.loading = true;

            axios.post("/tool/paragraphRewriter", {
                capcha_token: this.token,
                rawtext: this.rawtext,
                paragraphs: this.selectedOption,
                lang: 'english',
                mode: this.selectMode,
            })
                .then(response => {
                    console.log('Response:', response.data.content);
                    this.loading = false;
                    if (response.data.content.length) {
                        this.editortext = response.data.content;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    console.error('Error show:', e);
                    this.loading = false;
                    turnstile.reset('#cf-container');

                    const lettersOnly = /^[a-zA-Z\s]+$/;

                    if (e.response && e.response.status === 422) {
                        this.serverErrorMessage = e.response.data.message;
                        this.yourErrorHeaderVariable = "Error 422";
                    } else if (this.rawtext.trim() === '') {
                        this.errorMessage = "Input Text Required"; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Input Text Required.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    } else if (this.rawtext.trim().length < 6) {
                        this.errorMessage = "Content is too short, Please add at least 6 characters."; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Content is too short, Please add at least 6 characters.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    } else {
                        this.serverErrorMessage = "Something went wrong. Please try again and reload.";
                        this.yourErrorHeaderVariable = "Fatal Error";
                    }

                    // Show the error popup for other errors
                    this.$refs.errorPopup.showError = true;
                });
        }
    }
};
</script>
<style>
.ql-editor {
    height: 100% !important;
}
.ql-container {
    height: auto !important;
}
.ql-container.ql-snow {
    border: none!important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
}

.dropdown-content a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    font-weight: 400;
}

.dropdown-content a:hover {
    background-color: #E2E2E2;
}

.show-dropdown .dropdown-content {
    display: block;
}

.dropdownCount {
    position: relative;
    display: inline-block;
}
.dropdown-content-count {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
}

.dropdown-content-count a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    font-weight: 400;
}

.dropdown-content-count a:hover {
    background-color: #E2E2E2;
}

.show-dropdownCount .dropdown-content-count {
    display: block;
}

.show-dropdownCount .dropdown-content-count {
    display: block;
}
.textarea-scroll {
    width: 100%;
    height: 120px;
    overflow-y: auto;
    padding-bottom: 10px;
    resize: none;

}

</style>
